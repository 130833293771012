import React, { useEffect, useState, useRef } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import useLockBodyScroll from "../../hooks/useLockBodyScroll";
import links from "./links";

const Nav = () => {

  const [isOpened, setIsOpened] = useState(false);

  const checkboxRef = useRef(null);

  useEffect(() => {
    if (isOpened) {
      const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
          setIsOpened(false);
        }
      }
      document.addEventListener('keydown', handleKeyDown);
      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      }
    }
  }, [isOpened]);

  return (
    <>
    {isOpened && <LockedBodyScroll />}
    <input id="navToggle" type="checkbox" hidden ref={checkboxRef} checked={isOpened ? 'checked' : undefined} onChange={(e) => setIsOpened(e.target.checked)} />
    <StyledNav className={isOpened ? "is-open" : undefined}>
      <ul>
        <li>
          <NavToggle isOpened={isOpened} setIsOpened={setIsOpened} />
          <StyledNavOverlay className={isOpened ? `is-opened` : undefined} onClick={() => setIsOpened(false)} />
          <StyledNavGrid className={isOpened ? `is-opened` : undefined}>
            {links.map((section, index) => (
              <ul key={`nav-section-${index}`}>
                <li>{section.title}</li>
                {section.links.map((link, index) => (
                  <li key={`footer-link-${index}`}>
                    <Link to={link.url} title={link.title} activeClassName="is-active" onClick={() => setIsOpened(false)}>{link.label}</Link>
                  </li>
                ))}
              </ul>
            ))}
          </StyledNavGrid>
        </li>
        <li>
          <Link to="/reporting" title="Reporting" activeClassName="is-active" onClick={() => setIsOpened(false)}>Reporting</Link>
        </li>
        <li>
          <Link to="/about" title="About" activeClassName="is-active" onClick={() => setIsOpened(false)}>About</Link>
        </li>
      </ul>
      <div>
        <Link className="logo" to="/" title="The Outlaw Ocean Project" onClick={() => setIsOpened(false)}><small>The</small> Outlaw Ocean Project</Link>
      </div>
      <ul>
        <li>
          <a href="https://theoutlawocean.substack.com/subscribe" title="Subscribe to The Outlaw Ocean Project on Substack" target="_blank" rel="noopener noreferrer">Subscribe</a>
        </li>
        <li>
          <a className="donate" href="https://donate.stripe.com/fZe6rS0Qxc6M25y9AA" title="Donate to The Outlaw Ocean Project" target="_blank" rel="noopener noreferrer">Donate</a>
        </li>
      </ul>
    </StyledNav>
    </>
  );
};

export default Nav;

const LockedBodyScroll = () => {
  useLockBodyScroll();
  return null;
}

const StyledNavOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: -1;
  display: none;
  #navToggle:checked ~ * & {
    display: block;
  }
`;

const StyledNavGrid = styled.div`
  position: absolute;
  top: calc(100% - 1px);
  right: 0;
  left: 0;
  padding: 1.5em var(--gutter) 3em calc(var(--gutter) + 3.375em);
  display: none;
  gap: 3em;
  #navToggle:checked ~ * & {
    display: flex;
  }
  padding-top: 1.5em;

  @media ( max-width: 48em ) {
    flex-direction: column;
    gap: 2em;
    padding: 3em var(--gutter);
    text-align: center;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: var(--color-black);
    z-index: -1;
    border-bottom: 0.5px solid;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    @media ( min-width: 60em ) {
      gap: 1em;
    }
    li:first-of-type {
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.075em;
      line-height: 1;
      opacity: 0.5;
    }
    li a {
      font-size: 1.125em;
    }
  }
`;

const StyledNav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 1000;
  &.is-open {
    z-index: 5000;
  }
  display: grid;
  grid-template-columns: 13em 1fr 13em;
  padding: 1em var(--gutter);
  max-width: var(--max-width);
  margin: 0 auto;
  background-color: var(--color-black);
  align-items: center;
  height: var(--navbar-height);
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    background-color: inherit;
    z-index: -1;
    border-bottom: 0.5px solid;
  }
  > div {
    text-align: center;
    justify-content: center;
    display: flex;
  }
  .logo {
    font-family: var(--font-heading);
    display: block;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 1.25em;
    //letter-spacing: 0.1em;
    letter-spacing: 0.075em;
    line-height: 1;
    small {
      display: block;
      font-size: 0.6em;
      margin-bottom: 0.3em;
    }
    @media ( min-width: 60em ) {
      font-size: 1.375em;
    }
  }

  > ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    gap: 2em;
    align-items: center;
    &:last-of-type {
      justify-content: flex-end;
    }
  }
  a {
    font-weight: 500;
    line-height: 1;
    //transition: opacity 0.3s ease;
    .can-hover &:not(.is-active):hover,
    &:not(.is-active):active {
      opacity: 0.5;
    }
    &.donate {
      padding: 0.5em 1.25em;
      background-color: var(--color-white);
      color: var(--color-black);
      border-radius: 0.25rem;
    }
  }
  li a {
    font-size: 0.9375em;
    &.is-active,
    .can-hover &:hover,
    &:active {
      text-decoration-line: underline;
      text-decoration-thickness: 0.75px;
      text-underline-offset: 0.1em;
    }
  }
  @media ( max-width: 54em ) {
    grid-template-columns: 4em 1fr 4em;
    > ul:first-of-type {
      > li:not(:first-of-type) {
        display: none;
      }
    }
    > ul:last-of-type {
      > li:not(:last-of-type) {
        display: none;
      }
    }
    .logo {
      font-size: 1.125em;
      line-height: 1.075;
      small {
        margin-bottom: 0.2em;
        margin-top: -0.375em;
      }
    }
    a.donate {
      font-size: 0.875em;
      background: none;
      color: inherit;
      padding: 0;
    }

  }
  /*
  hr {
    width: 1px;
    height: 1em;
    background-color: currentColor;
    margin: 0;
    border: 0;
  }
    */
`;


const NavToggle = () => {
  const navDrawerVisibility = false;
  return (
    <StyledNavToggle 
      htmlFor="navToggle" 
      tabIndex="0"
    >
      <StyledNavToggleIcon className={navDrawerVisibility ? `nav-opened` : undefined} />
    </StyledNavToggle>
  );
};

const StyledNavToggleIcon = styled.i`

  @keyframes top-in {
    0% {
      transform: translate3d(0,-4px,0);
    }
    60% {
      transform: translate3d(0,0,0);
    }
    100% {
      transform: translate3d(0,0,0) rotate(45deg);
    }
  }
  @keyframes top-out {
    0% {
      transform: translate3d(0,0,0) rotate(45deg);
    }
    60% {
      transform: translate3d(0,0,0);
    }
    100% {
      transform: translate3d(0,-4px,0);
    }
  }
  @keyframes bottom-in {
    0% {
      transform: translate3d(0,4px,0);
    }
    60% {
      transform: translate3d(0,0,0);
    }
    100% {
      transform: translate3d(0,0,0) rotate(-45deg);
    }
  }
  @keyframes bottom-out {
    0% {
      transform: translate3d(0,0,0) rotate(-45deg);
    }
    60% {
      transform: translate3d(0,0,0);
    }
    100% {
      transform: translate3d(0,4px,0);
    }
  }
  position: relative;
  z-index: 1000;
  display: block;
  height: 1.75em;
  width: 1.375em;
  transition: transform 0.4s ease;
  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: calc(50% - 1px);
    width: 100%;
    height: 2px;
    background-color: currentColor;
    transition: transform 0.4s ease;
  }
  &::before {
    animation: top-out 0.4s ease forwards;
  }
  &::after {
    animation: bottom-out 0.4s ease forwards;
  }
  #navToggle:checked ~ * &,
  &.nav-opened {
    //transform: scale(0.8);
    &::before {
      animation: top-in 0.4s ease forwards;

    }
    &::after {
      animation: bottom-in 0.4s ease forwards;
    }
  }
`

const StyledNavToggle = styled.label`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  z-index: 5;
  cursor: pointer;
  span {
    font-size: 0.6875em;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 0.9;
    letter-spacing: 0.05em;
    transition: opacity 0.2s ease;
    margin: 0.025em 0 0;
    transform: translate3d(0,0.075em,0);
    @media ( max-width: 48em ) {
      display: none;
    }
  }
  #navToggle:checked ~ * &,
  &.nav-opened {
    span {
      opacity: 0 !important;
    }
  }

`;